import type { FormType, SubmitBtnType } from "haq"
import { makeAppEvents } from "haq"

export type GlobalActions = {
	"login-form-submitted": {
		form: FormType
		submitBtn: SubmitBtnType<AppBtn>
	}
}

const APP_EVENTS = makeAppEvents<GlobalActions>()
export default APP_EVENTS
