//* #region ----------------------------------------------- Types

import type { GlobalActions } from "../init/events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports

import { makeFormRequest } from "front-end-helpers"

//* #endregion ----------------------------------------------- Module Imports

export default {
	execute: (data: GlobalActions["login-form-submitted"]) => {
		const { form, submitBtn } = data
		const formRequest = makeFormRequest({ form, submitBtn })
		formRequest.init()
	}
}
